<template>
  <svg viewBox="0 0 166 30"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       aria-hidden="true"
  >
    <g clip-path="url(#clip0_277_11931)"
       fill="currentColor"
    >
      <path d="M15.38.02A15 15 0 0 0 .43 15.04a14.79 14.79 0 0 0 14.95 14.94 14.98 14.98 0 0 0 0-29.96Zm0 22.28a7.28 7.28 0 1 1 .03-14.57 7.28 7.28 0 0 1-.03 14.57ZM56.34 0h-4.87a1.72 1.72 0 0 0-1.72 1.72V17.7a3.9 3.9 0 1 1-7.82 0V1.72A1.72 1.72 0 0 0 40.21 0h-4.84a1.75 1.75 0 0 0-1.73 1.72v16.29a12.21 12.21 0 0 0 24.42 0V1.71A1.8 1.8 0 0 0 56.34 0ZM80.94 0H63.18a1.75 1.75 0 0 0-1.75 1.75v4.59a1.74 1.74 0 0 0 1.75 1.74h4.72V28.2a1.78 1.78 0 0 0 1.75 1.75h4.8a1.77 1.77 0 0 0 1.74-1.75V8.09h4.75a1.74 1.74 0 0 0 1.74-1.75v-4.6A1.73 1.73 0 0 0 80.94 0ZM137.54 0H119.8a1.74 1.74 0 0 0-1.75 1.75v4.59a1.74 1.74 0 0 0 1.75 1.74h4.72V28.2a1.77 1.77 0 0 0 1.75 1.75h4.8a1.77 1.77 0 0 0 1.74-1.75V8.09h4.73a1.73 1.73 0 0 0 1.74-1.75v-4.6A1.74 1.74 0 0 0 137.54 0ZM101.4 0H87.72a1.75 1.75 0 0 0-1.74 1.75v26.44a1.75 1.75 0 0 0 1.74 1.75h4.7a1.78 1.78 0 0 0 1.76-1.75v-8.7h5.29a1.78 1.78 0 0 0 1.75-1.76V13.8a1.77 1.77 0 0 0-1.75-1.75h-5.29V8.1h7.22a1.74 1.74 0 0 0 1.76-1.75V1.77A1.75 1.75 0 0 0 101.4 0ZM113 12.01h-4.79a1.77 1.77 0 0 0-1.74 1.75v14.4a1.77 1.77 0 0 0 1.74 1.76h4.8a1.78 1.78 0 0 0 1.74-1.75V13.79A1.79 1.79 0 0 0 113 12ZM113 0h-4.79a1.77 1.77 0 0 0-1.74 1.75v4.59a1.76 1.76 0 0 0 1.74 1.74h4.8a1.77 1.77 0 0 0 1.74-1.74v-4.6A1.78 1.78 0 0 0 113 0ZM146.03 29.94h4.07a2.69 2.69 0 0 0 2.59-1.76l12.2-25.48a2.05 2.05 0 0 0 .2-1.05A1.84 1.84 0 0 0 163.3 0h-17.38a1.8 1.8 0 0 0-1.8 1.8v4.58a1.8 1.8 0 0 0 1.8 1.8h6.69a.75.75 0 0 1 .75.71c.01.13-.01.26-.07.37l-8.9 17.96a1.87 1.87 0 0 0 .15 1.96l.08.1a1.8 1.8 0 0 0 1.4.66Z" />
    </g>
  </svg>
</template>
